import { useEffect } from 'react'

import { useLocation } from '@reach/router'
import queryString from 'query-string'

const Login = () => {
  const location = useLocation()
  const { redirectTo } = queryString.parse(location.search ?? '')

  useEffect(
    () =>
      redirectTo
        ? (window.location.href = redirectTo)
        : (window.location.href = '/'),
    [redirectTo]
  )

  return null
}

export default Login
